/* You can add global styles to this file, and also import other style files */
/* All sortable column of table are blue */

*{
    box-sizing: border-box;
  }
  
  
  html,body{
    background: #252525;
    color: white;
    height: 100vh;
  }
  
  
  th[sortable]{
      color: #192EB0;
  }
  
  tr{
    font-size: 11px;
  }
  tr th{
    font-size: 13px;
  }
  table th{
    font-size: 13px !important;
  }
  table td{
    font-size: 11px !important;
  }
  table tr th:first-child{
    width: 60px !important;
  }
  
  /* table tr td:first-child{
    width: 30px !important;
  } */
  input:disabled{
    cursor: not-allowed;
  }
  
  select:disabled{
    cursor: not-allowed;
  }
  
  /* css issues */
  .flex_row{
    display: flex  !important;
    flex-direction: row !important;
  }
  .flex_column{
    display: flex !important;
    flex-direction: column !important;
    margin-top: 2px;
  }
  .flex_grow_2{
    flex-grow: 2;
  }
  .flex_wrap{
    flex-wrap: wrap !important;
  }
  .justify_content_space_evenly{
    justify-content: space-evenly !important;
  }
  .justify_content_space_around{
    justify-content: space-around !important;
  }
  .justify_content_center{
    justify-content:center !important;
  }
  /* .container{
    width: calc(80vw) ;
    margin: 0 auto !important;
  } */
  .container_expand{
    width: 100% !important;
    height: 100% !important;
  }
  @media only screen and (max-width: 700px) {
    .container_expand {
      width: fit-content !important;
    }
  }
  
  
  .vertical span{
    /* display: flex; */
    /* flex-direction: column; */
    width: 80%;
  }
  /* .vertical span span{
    width: 100%;
    display: block;
  } */
  
  
  input,select,option{
    background-color: #585858 !important;
    color: #fff !important;
  }
  
  
  
  html *::-webkit-scrollbar {
    width: 5px !important;
    height:5px !important;
  }
  
  html *::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
  }
  
  html *::-webkit-scrollbar-thumb {
  background-color: #313131 !important;
  outline: 1px solid #191919 !important;
  }
  
  .page-link{
    background-color: #252525 !important;
    color: #fff !important;
  }
  
  .page-item.active .page-link{
    background-color: rgb(26, 26, 169) !important;
    color: #fff !important;
  }
  
  .modal-backdrop {
    z-index: 960 !important;
  }
  .modal{
    z-index: 961 !important;
  }
  
  .modal-open .modal  {
    background: rgb(0,0,0,80%) !important;
  }
  .swal2-input{
    max-width: 19em !important;
  background-color: white !important;
  color:#313131 !important;
  }
  .pac-container{
    z-index: 2000;
  }

  .multiSelect .ui-multiselect  {
    /* Your styles for the ui-multiselect class */
    background: none;
    border: none;
    max-width: 65px;
  }
  .multiSelect .ui-multiselect .ui-multiselect-label{
    font-size: 9px;
    height: 22px !important;
  }

  .multiSelect .ui-multiselect .ui-multiselect-trigger{
    background: none;
    top: -2px !important;
    width: auto;
  }

  .multiSelect .ui-multiselect .ui-multiselect-trigger-icon{
    font-size: 0.8em !important;
  }
 